import { IMAGE_URL, TENX_IMAGE_URL } from "../config";

export const scrollToElementWithMargin = (
  elementId: string,
  behavior  = "smooth"
) => {
  const element = document.getElementById(elementId);
  if (element) {
    element.scrollIntoView({
       //@ts-ignore 
      behavior,
    });

  }
};


export const  goToIdPage = (url : string  , value : string ,idKey=":id")=>{
  return url.replace(idKey  , value)
}

export const getPageDetails = (dataLength : number  , perPage : number , currentPage :  number)=>{
  const pageCount =  Math.ceil(Number(dataLength)/ Number(perPage) ) 
  const page =   Number(currentPage) - 1;
  return  {pageCount , page}
}   


export const formatRadioOptions = (options = [] , labelKey : string , valueKey : string)=>{
  return options.map((x:any)=>{
    return {
      ...x,
      label : x[labelKey],
      value : x[valueKey]
    }
  }) || []
}


export function calculateTimeAgo(date:string) {
  const seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);

  if (seconds < 30) return 'Just now';

  const intervals = {
      year: 31536000,
      month: 2592000,
      week: 604800,
      day: 86400,
      hour: 3600,
      minute: 60,
      second: 1
  };

  for (const unit in intervals) {
    //@ts-ignore
      const intervalSeconds = intervals[unit];
      const counter = Math.floor(seconds / intervalSeconds);

      if (counter > 0) {
          return counter + ' ' + unit + (counter === 1 ? '' : 's') + ' ago';
      }
  }

  return ''; // Fallback in case no match is found
}


export const getImageWithBaseUrl = (url="")=>{
  return IMAGE_URL as string +  (url.startsWith("/") ? url  : `/${url}`)
}
export const getTenxImageWithBaseUrl = (url="")=>{
  return TENX_IMAGE_URL as string +  (url.startsWith("/") ? url  : `/${url}`)
}