import React from "react";
import styles from "./Tabs.module.scss";

const Tabs = ({
  tabList,
  activeIndex,
  onActiveIndex,
  disabled
}: {
  tabList: string[];
  activeIndex: number;
  onActiveIndex : (index : number)=>void;
  disabled : boolean
}) => {
  return (
    <div className={styles.tabWrapper}>
      {tabList.map((t, index) => {
        return (
          <button
            key={t}
            onClick={()=>{
              if(!disabled){
                onActiveIndex(index)
              }
            }}
            className={`${styles.tab} ${
              index === activeIndex && styles.selected
            }`}
          >
           {t}
          </button>
        );
      })}
    </div>
  );
};

export default Tabs;
